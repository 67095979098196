import type { SerializeFrom } from "@remix-run/server-runtime";

import useImageUtils from "~/hooks/useImageUtils";
import type { C_ProductInfo } from "~/lib/app.types";
import { cn } from "~/lib/ui";
import { getVariantImage } from "~/shop/utils/image";

import Image from "../../../../components/ui/image";

export const ProductListingImage = ({
  product,
  isSmall,
  className,
}: {
  product: SerializeFrom<C_ProductInfo> | undefined;
  isSmall?: boolean;
  className?: string;
}) => {
  const { getImageLink } = useImageUtils();
  if (!product) return null;
  const [image] = getVariantImage(product) ?? [];
  return (
    <div
      className={cn(
        !isSmall &&
          "h-[200px] w-[150px] flex-shrink-0 overflow-hidden rounded-lg border border-gray-200",
      )}
    >
      <Image
        src={getImageLink(image.link)}
        alt={product.name}
        className={cn(
          isSmall ? "mr-1 h-24" : "h-full w-full",
          "object-cover object-center",
          className,
        )}
      />
    </div>
  );
};
